import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

export const drawerWidth = 350;

export const FORM_TYPE = {
    ONBOARD_EMPLOYEE: {
        TYPE: "ONBOARD-EMPLOYEE",
        TITLE: "onboardFormTitle",
        ICON: PersonAddAltOutlinedIcon
    },
    OFFBOARD_EMPLOYEE: {
        TYPE: "OFFBOARD-EMPLOYEE",
        TITLE: "offboardFormTitle",
        ICON: PersonOffOutlinedIcon
    },
    NEW_SITE_TEAM: {
        TYPE: "NEW-SITE-TEAM-REQUEST",
        TITLE: "newSiteTeamFormTitle",
        ICON: GroupsOutlinedIcon
    },
    MANAGE_SITE_USERS: {
        TYPE: "ADD-REMOVE-SITE-USERS",
        TITLE: "manageSiteFormTitle",
        ICON: ManageAccountsOutlinedIcon
    },
    MANAGE_SHARED_MAILBOXES: {
        TYPE: "ADD-MODIFY-SHARED-MAILBOXES",
        TITLE: "manageMailboxFormTitle",
        ICON: EmailOutlinedIcon
    },
    OTHER_REQUEST: {
        TYPE: "OTHER-REQUEST",
        TITLE: "otherRequestFormTitle",
        ICON: EditNoteOutlinedIcon
    }
}

export const ROUTES = {
    HOME: "/", 
    REPORTS: "/reports",
    SECURITYDASHBOARD: "/securitydashboard",
    LICENSEDASHBOARD: "/licensedashboard",
    IDENTITYDASHBOARD: "/identitydashboard_old",
    EMAILDASHBOARD: "/emaildashboard_old",
    CISDASBOARD: "/cisdashboard",
    EMAILSUPERSETDASHBOARD: "/emaildashboard",
    IDENTITYSUPERSETDASHBOARD: "/identitydashboard",
    LOGOUT: "/logout",
    FORMS: "/form",
    ERROR: "/error",
    NOT_FOUND: "/not-found"
}