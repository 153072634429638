import React, { useEffect, useRef, useState } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import type { EmbedConfig } from '../models/EmbedConfig';
import '../styles/css/SupersetViewer.css';


interface SupersetViewerProps {
  config: EmbedConfig;
  onSessionExpired?: () => void;
}

// JWT payload interface
interface JwtPayload {
  iat?: number;
  exp?: number;
  [key: string]: any;
}

// JWT decoding function (without validation)
const decodeJwt = (token: string): JwtPayload | null => {
  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid JWT format');
    }

    // Decode the payload (second part)
    const payloadBase64 = parts[1].replace(/-/g, '+').replace(/_/g, '/');
    const payloadJson = atob(payloadBase64);
    return JSON.parse(payloadJson);
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};

// Check if token is expired
const isTokenExpired = (token: string): boolean => {
  const payload = decodeJwt(token);

  // If no payload, token will be invalid
  if (!payload) return true;

  const currentTime = Math.floor(Date.now() / 1000);
  // check if the current time exceeded the exp time stamp
  if (payload.exp) {
    return currentTime >= payload.exp;
  }

  // check if token exceeded the lifetime limit
  if (payload.iat) {
    const tokenTimeout = parseInt(process.env.REACT_APP_SUPERSET_GUEST_TOKEN_TIMEOUT, 10);
    console.log('Token lifetime is ',tokenTimeout);
    return currentTime - payload.iat > tokenTimeout;
  }

  // If no time information, token will be invalid
  return true;
};

const SupersetViewer: React.FC<SupersetViewerProps> = ({ config }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);

  // Check token lifetime
  useEffect(() => {
    console.log('Checking token validity');

    if (!config.token) {
      console.error('No token provided');
      setSessionExpired(true);
      return;
    }

    const tokenExpired = isTokenExpired(config.token);

    if (tokenExpired) {
      console.warn('Token is expired!');
      setSessionExpired(true);
    }
  }, [config.token]);

  useEffect(() => {
    if (!containerRef.current || sessionExpired) return;

    const initializeSuperset = async () => {
      try {
        await embedDashboard({
          id: config.uuid,
          supersetDomain: config.url,
          mountPoint: containerRef.current,
          fetchGuestToken: () => Promise.resolve(config.token),
          dashboardUiConfig: {
            hideTitle: true,
            hideTab: false,
            hideChartControls: false,
            filters: {
              expanded: true
            }
          },
        });
      } catch (error) {
        console.error('Error initializing Superset dashboard:', error);
        const errorStr = String(error);
        const authenticationErrors = [
          '401',
          'Unauthorized',
          'Not authorized',
          'InvalidTokenError',
          'invalid json',
          'Unexpected token'
        ];

        if (authenticationErrors.some(err => errorStr.includes(err))) {
          setSessionExpired(true);
        }
      }
    };

    initializeSuperset();

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [config, sessionExpired]);

  const handleRefresh = (): void => {
    window.location.reload();
  };

  return (
    <div className="superset-container">
      <div ref={containerRef} className="superset-content"></div>

      {sessionExpired && (
        <div className="session-expired-overlay">
          <div className="session-message-card">
            <h3>Session Expired</h3>
            <p>Your dashboard session has expired or your token is invalid. Please refresh the page to continue.</p>
            <button onClick={handleRefresh} className="refresh-button">
              Refresh Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupersetViewer;