import { useState } from "react";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SideBarData from "../routers/sidebar";
import AppBar from "./AppBar";
import { ROUTES } from "../constant";
import { dataFromApi } from "../redux/getApiData/reducer";
import { embedConfig } from "../redux/getEmbedConfig/reducer";
import { useAppSelector } from "../hooks/hook";
import logo from "../images/074aca08e8f34889806806557004a2a9.png";
import { useTranslation } from "react-i18next";
import { hasReportPermission } from "../redux/getEmbedConfig/reducer";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

type Props = {
  children?: React.ReactNode;
};

export const PersistentDrawerLeft: React.FC<Props> = ({ children }) => {
  const apiData = useAppSelector(dataFromApi);
  const myEmbedConfig = useAppSelector(embedConfig);
  const hasReportAccessPermission = useAppSelector(hasReportPermission);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();

  // Generate short name from company name
  const getShortName = (name: string | undefined): string => {
    if (!name) return "SW"; // Default fallback

    // If name contains multiple words, use initials
    if (name.includes(" ")) {
      return name
        .split(" ")
        .map(word => word[0])
        .join("")
        .toUpperCase()
        .slice(0, 3); // Limit to 3 characters
    }

    // If it's a single word, take first 3 characters
    return name.substring(0, 3).toUpperCase();
  };

  const shortCompanyName = getShortName(apiData?.name);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Sidebar width based on collapsed state
  const sidebarWidth = collapsed ? 100 : 420;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="appbar-background"
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ minHeight: 112, height: 112 }}
        >
          <img src={logo} alt="logo" className="logo" />
          <Typography
            color="secondary"
            style={{
              flexGrow: 1,
              paddingLeft: "15px",
              marginTop: "30px",
            }}
          >
            <Link
              component={RouterLink}
              to={ROUTES.HOME}
              color="inherit"
              variant="h6"
              underline="none"
              fontFamily="Poppins"
              fontSize="40px"
              fontWeight="300"
              onClick={() => handleListItemClick(0)}
            >
              central view
            </Link>
          </Typography>
          <WelcomeName />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          overflow: "hidden", /* Changed from overflowX to overflow to fully hide all scrollbars */
          whiteSpace: "nowrap",
        }}
        className="sidebar"
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ minHeight: 112, height: 112 }}
        />

        {/* MODIFIED LAYOUT: Moved toggle button above company name and reduced spacing */}
        <Box sx={{
          padding: 0,
          marginBottom: 0,
          display: "flex",
          flexDirection: "column",
        }}>
          {/* Toggle Button - Now positioned above company name */}
          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 1,
            marginBottom: 0
          }}>
            <IconButton onClick={toggleSidebar}>
              {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Box>

          {/* Company Name - Now positioned right below toggle button */}
          <Box sx={{
            padding: collapsed ? 1 : 2,
            paddingTop: 0,
            marginBottom: 1
          }}>
            {collapsed ? (
              <Tooltip title={apiData?.name || ""} placement="right">
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    lineHeight: "36px",
                    textAlign: "center",
                    paddingTop: 0,
                    height: "72px", // Fixed height for 2 lines (36px × 2)
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  className="tenant-text"
                >
                  {shortCompanyName}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                variant="h6"
                color="secondary"
                sx={{
                  fontSize: 24,
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  lineHeight: "36px",
                  textAlign: "left",
                  paddingTop: 0,
                  height: "72px", // Fixed height for 2 lines (36px × 2)
                  maxWidth: "100%",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textWrap: "wrap",
                  opacity: 0,
                  animation: collapsed ? "none" : "fadeIn 0.5s forwards 0.25s",
                  "@keyframes fadeIn": {
                    "0%": { opacity: 0 },
                    "100%": { opacity: 1 }
                  }
                }}
                className="tenant-text"
              >
                {apiData?.name}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Divider */}
        <Divider variant="middle" />

        {/* Submit a Ticket Section */}
        <Box sx={{
          overflow: "hidden", /* Changed from "auto" to hide the scrollbar during transitions */
          paddingLeft: collapsed ? "8px" : "16px",
          mt: 1,
          mb: 1,
        }}>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontWeight: "700",
              textAlign: collapsed ? "center" : "left",
              py: 1,
              px: collapsed ? 0 : 1,
            }}
            className="sidebar-title"
          >
            {collapsed ? (
              <Tooltip title={t("formSideBarTitle")} placement="right">
                <span>{t("formSideBarTitleShort")}</span>
              </Tooltip>
            ) : (
              t("formSideBarTitle")
            )}
          </Typography>
          <List
            sx={{
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "rgba(143, 32, 27, 0.15)",
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "rgba(143, 32, 27, 0.15)",
              },
              "& .MuiListItem-root": {
                display: "block",
                py: 0.5, // Consistent padding for both states
              }
            }}
          >
            {SideBarData.formSideBar.map((item, index) => (
              <ListItem key={t(item.title)} disablePadding>
                {collapsed ? (
                  <Tooltip title={t(item.title)} placement="right">
                    <ListItemButton
                      component={RouterLink}
                      to={item.route}
                      selected={selectedIndex === index + 1}
                      onClick={() => handleListItemClick(index + 1)}
                      sx={{
                        py: 1, // Consistent padding for both states
                        px: 1,
                        justifyContent: "center",
                        height: "48px", // Fixed height for consistency
                      }}
                    >
                      <ListItemIcon sx={{
                        minWidth: 0,
                        marginRight: 0,
                        justifyContent: "center"
                      }}>
                        {<item.icon />}
                      </ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                ) : (
                  <ListItemButton
                    component={RouterLink}
                    to={item.route}
                    selected={selectedIndex === index + 1}
                    onClick={() => handleListItemClick(index + 1)}
                    sx={{
                      py: 1, // Consistent padding for both states
                      px: 2,
                      justifyContent: "flex-start",
                      height: "48px", // Fixed height for consistency
                    }}
                  >
                    <ListItemIcon sx={{
                      minWidth: 40,
                      marginRight: 2,
                      justifyContent: "center"
                    }}>
                      {<item.icon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={t(item.title)}
                      primaryTypographyProps={{
                        fontSize: "16px",
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                      }}
                    />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Divider */}
        {hasReportAccessPermission ? (
          <>
            <Divider variant="middle" />

            {/* View My Reports Section */}
            <Box sx={{
              overflow: "hidden", /* Changed from "auto" to hide the scrollbar during transitions */
              paddingLeft: collapsed ? "8px" : "16px",
              mt: 1,
              mb: 1,
            }}>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{
                  color: "#8F201B",
                  fontSize: "16px",
                  fontFamily: "Open Sans",
                  fontWeight: "700",
                  textAlign: collapsed ? "center" : "left",
                  py: 1,
                  px: collapsed ? 0 : 1,
                }}
                className="sidebar-title"
              >
                {collapsed ? (
                  <Tooltip title={t("reportSideBarTitle")} placement="right">
                    <span>{t("reportSideBarTitleShort")}</span>
                  </Tooltip>
                ) : (
                  t("reportSideBarTitle")
                )}
              </Typography>
              <List
                sx={{
                  // selected and (selected + hover) states
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    bgcolor: "rgba(143, 32, 27, 0.15)",
                  },
                  // hover states
                  "& .MuiListItemButton-root:hover": {
                    bgcolor: "rgba(143, 32, 27, 0.15)",
                  },
                  "& .MuiListItem-root": {
                    display: "block",
                    py: 0.5, // Consistent padding for both states
                  }
                }}
              >
                {myEmbedConfig.map((item, index) => (
                  <ListItem key={t(item.title)} disablePadding>
                    {collapsed ? (
                      <Tooltip title={t(item.title)} placement="right">
                        <ListItemButton
                          component={RouterLink}
                          to={`/reports/${item.title}`}
                          selected={
                            selectedIndex ===
                            index + 1 + SideBarData.formSideBar.length
                          }
                          onClick={() =>
                            handleListItemClick(
                              index + 1 + SideBarData.formSideBar.length
                            )
                          }
                          sx={{
                            py: 1, // Consistent padding for both states
                            px: 1,
                            justifyContent: "center",
                            height: "48px", // Fixed height for consistency
                          }}
                        >
                          <ListItemIcon sx={{
                            minWidth: 0,
                            marginRight: 0,
                            justifyContent: "center"
                          }}>
                            {<AssessmentOutlinedIcon />}
                          </ListItemIcon>
                        </ListItemButton>
                      </Tooltip>
                    ) : (
                      <ListItemButton
                        component={RouterLink}
                        to={`/reports/${item.title}`}
                        selected={
                          selectedIndex ===
                          index + 1 + SideBarData.formSideBar.length
                        }
                        onClick={() =>
                          handleListItemClick(
                            index + 1 + SideBarData.formSideBar.length
                          )
                        }
                        sx={{
                          py: 1, // Consistent padding for both states
                          px: 2,
                          justifyContent: "flex-start",
                          height: "48px", // Fixed height for consistency
                        }}
                      >
                        <ListItemIcon sx={{
                          minWidth: 40,
                          marginRight: 2,
                          justifyContent: "center"
                        }}>
                          {<AssessmentOutlinedIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={t(item.title)}
                          primaryTypographyProps={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                          }}
                        />
                      </ListItemButton>
                    )}
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        ) : null}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          transition: (theme) =>
            theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ minHeight: 112, height: 112 }}
        />
        <div className="container">{children}</div>
      </Box>
    </Box>
  );
};